import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';

import * as Core from 'components/core';

import { useCurrentIssue } from 'context/IssueContext';

import CoverLayout from 'components/cover/Cover';
import SaveTheDate from 'components/cover/SaveTheDate';

import { Props as DeansNotesProps } from 'components/cover/DeansNote';

const Cover: React.FC = () => {
  const { issue, features } = useCurrentIssue();

  const { deansNote } = useStaticQuery<{
    deansNote: DeansNotesProps;
  }>(graphql`
    query {
      deansNote: markdownRemark(
        fileAbsolutePath: { regex: "/fall-2019/deans-note/" }
      ) {
        ...DeansNote
      }
    }
  `);

  return (
    <CoverLayout
      issue={issue}
      features={features}
      deansNote={deansNote}
      coverFooter={
        <SaveTheDate
          dates={[
            <div key={0}>
              <Core.BoldText>
                <Core.ExternalLink href="https://75.sais-jhu.edu">
                  75th Anniversary Shape the World Celebration
                </Core.ExternalLink>
              </Core.BoldText>
              <br />
              Washington, DC
              <br />
              October 24 – 25, 2019
            </div>,
            <div key={1}>
              <Core.BoldText>
                <Core.ExternalLink href="http://mediahost.sais-jhu.edu/~saismedia/media/aw19/index.htm">
                  Reunion Weekend
                </Core.ExternalLink>
              </Core.BoldText>
              <br />
              Washington, DC
              <br />
              October 25 – 26, 2019
            </div>,
            <div key={2}>
              <Core.BoldText>
                <Core.ExternalLink href="http://mediahost.sais-jhu.edu/~saismedia/media/aw19/index.htm">
                  I Bolognesi a Londra
                </Core.ExternalLink>
              </Core.BoldText>
              <br />
              London
              <br />
              November 23, 2019
            </div>,
            <div key={3}>
              <Core.BoldText>
                SAIS Europe 65th Anniversary Celebrations & Alumni Weekend
              </Core.BoldText>
              <br />
              Bologna
              <br />
              May 1-3, 2020
            </div>
          ]}
        />
      }
    />
  );
};

export default Cover;
